.nav-img {
  margin-right: 10px;
}

.color-nav {
  background-color: #4a4a4a;
}

.col-height {
  height: 488px !important;
}

.no-padding {
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.body-no-button-layout {
  font-size: 0;
  overflow: hidden;
}

.no-margin {
  margin-right: 0px !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.padding-top {
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 17px;
  padding-bottom: 0px;
}

.player-container {
  padding-bottom: 50px;
  height: 100%;
}

.left-padding {
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.left-right-padding {
  padding-top: 0px;
  padding-bottom: 0px;
}

.left-top-padding {
  padding-right: 0px;
  padding-top: 17px;
  padding-bottom: 0px;
}

.swap-button-padding-left {
  padding-right: 0px;
  padding-left: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.swap-button-padding-right {
  padding-right: 0px;
  padding-left: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.no-margin {
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.left-margin {
  margin-left: 10px;
}

.form-control:focus {
  border-color: #c5c5c7;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(63, 63, 63, 0.6);
}

.height {
  height: 488px;
}

.red {
  color: red;
}

.orange {
  background-color: orange;
}

.blue {
  background-color: blue;
}
.yellow {
  background-color: yellow;
}

.green {
  color: rgb(0, 155, 0);
}

.zeroPadding {
  margin: 0px;
}

.button {
  font-size: 12px;
  display: inline-block;
  vertical-align: top;
  margin-top: 30px;
  text-align: left;
}

.playerMargin {
  margin-top: 10px;
}

.playerLabel {
  font-weight: bold;
  font-size: 22px;
}

.formLabel {
  margin-top: 10px;
  font-size: 16px;
}

.formMargin {
  margin-bottom: 20px;
}

.demoBtn {
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
}

.radio-padding {
  padding-top: 20px;
}

.buildStreamBtn {
  margin-top: 10px;
  margin-bottom: 10px;
}

.zeroPaddingMarigin {
  margin: 0px;
  padding: 0px;
}

.zeroPadding {
  padding: 0px;
}

.white {
  background-color: white;
}
